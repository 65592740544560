const origin = window.location.origin

export const getLocalSpItems = () => {
  return [
    {
      name: 'Start',
      path: '/dashboard',
      meta: {
        icon: 'home',
        illustration: 'home',
        market: ['SE', 'DK', 'FI', 'NO'],
        isExternal: false,
        requiresAuth: true
      }
    },
    {
      name: 'FALLBACK.TITLE',
      description: 'FALLBACK.DESCRIPTION',
      path: '/fallback/items',
      meta: {
        icon: 'alert_exclamation_circle',
        illustration: 'truck_house_arrow',
        market: ['SE', 'DK', 'FI', 'NO'],
        isExternal: false,
        requiresAuth: true
      }
    },
    {
      name: 'FORECAST.TITLE',
      description: 'FORECAST.DESCRIPTION',
      path: '/forecast',
      meta: {
        icon: 'statistics',
        illustration: 'package_calendar',
        market: ['DK'],
        isExternal: false,
        requiresAuth: true,
        capability: 'servicepointBackoffice.forecastEnable'
      }
    },
    {
      name: 'DAILY_BALANCE.TITLE',
      description: 'DAILY_BALANCE.DESCRIPTION',
      path: '/daily-balance',
      meta: {
        icon: 'document_check',
        illustration: 'character_computer_graphics',
        market: ['SE'],
        isExternal: false,
        requiresAuth: true,
        capability: 'cashier.acceptCashPayments'
      }
    },
    {
      name: 'CASH_DELIVERY.TITLE',
      description: 'CASH_DELIVERY.DESCRIPTION',
      url: `${origin}/cash-delivery/`,
      meta: {
        icon: 'money',
        illustration: 'truck_big_mountains',
        market: ['SE'],
        isExternal: false,
        requiresAuth: true,
        capability: 'cashier.acceptCashPayments'
      }
    },
    {
      name: 'CUSTOMER_MANAGEMENT.TITLE',
      description: 'CUSTOMER_MANAGEMENT.DESCRIPTION',
      path: '/manage-customer',
      meta: {
        icon: 'claim',
        illustration: 'postnord_customer_service',
        market: ['SE'],
        isExternal: false,
        requiresAuth: true,
        capability: 'deliveryAgreement.enable'
      }
    },
    {
      name: 'DELIVERY_LIST.TITLE',
      description: 'DELIVERY_LIST.DESCRIPTION',
      path: '/delivery-list',
      meta: {
        icon: 'postcard',
        illustration: 'computer_postcard',
        market: ['SE'],
        isExternal: false,
        requiresAuth: true,
        capability: 'deliveryAgreement.enable'
      }
    },
    {
      name: 'EBUTIKEN.TITLE',
      description: 'EBUTIKEN.DESCRIPTION',
      path: '/e-butiken',
      meta: {
        icon: 'shop',
        illustration: 'character_package_sweather',
        market: ['SE'],
        isExternal: false,
        requiresAuth: true,
        capability: 'servicepointBackoffice.eshopEnable'
      }
    },
    {
      name: 'BASEREGISTER.TITLE',
      description: 'BASEREGISTER.DESCRIPTION',
      path: '/baseregistration',
      meta: {
        icon: 'secure_transfer',
        illustration: 'person_computer',
        market: ['SE'],
        isExternal: false,
        requiresAuth: true,
        capability: 'servicepointBackoffice.baseRegistrationEnable'
      }
    },
    {
      name: 'POSTPORTAL.TITLE',
      description: 'POSTPORTAL.DESCRIPTION',
      url: 'https://postnord.humany.net/postombudmobil',
      meta: {
        icon: 'mailbox_postnord',
        illustration: 'postboxes',
        market: ['SE'],
        isExternal: true,
        requiresAuth: true
      }
    },
    {
      name: 'APP.PARTNER_WEB',
      description: 'APP.PARTNER_WEB_DESCRIPTION',
      url: process.env.VUE_APP_PARTNER_WEB_URL,
      meta: {
        icon: 'credit_card',
        illustration: 'file_paper_arrow',
        isExternal: true
      }
    }
  ]
}
